"use strict";
/** Noise and tones generator/player. */
exports.__esModule = true;
var context_1 = require("./context");
exports.PANNING_LEFT = -1;
exports.PANNING_CENTER = 0;
exports.PANNING_RIGHT = 1;
exports.VOLUME_MIN = 0;
exports.VOLUME_MAX = 1;
/**
 * Play tones and noises.
 *
 * A Player optionally takes the following parameters:
 * - an AudioContext, else one will be created.
 * - an output AudioNode, else the destination of the context will be used.
*/
var WoPlayer = /** @class */ (function () {
    function WoPlayer(options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        /** Play a tone. */
        this.playTone = function (frequency, panning, volume, type) {
            if (type === void 0) { type = "triangle"; }
            var _a = _this.sanitizePlayParameters({ panning: panning, volume: volume }), sanePanning = _a.panning, saneVolume = _a.volume;
            var osc = _this.context.createOscillator();
            osc.type = type;
            osc.frequency.value = frequency;
            var node = osc;
            node = _this.addPannerNode(node, sanePanning);
            node = _this.addVolumeNode(node, saneVolume);
            node.connect(_this.master);
            osc.start();
            return osc;
        };
        /** Play precomputed white noise. */
        this.playWhiteNoise = function (panning, volume) {
            var _a = _this.sanitizePlayParameters({ panning: panning, volume: volume }), sanePanning = _a.panning, saneVolume = _a.volume;
            var noiseSource = _this.context.createBufferSource();
            noiseSource.buffer = _this.whiteNoiseBuffer;
            noiseSource.loop = true;
            var node = noiseSource;
            node = _this.addPannerNode(node, sanePanning);
            node = _this.addVolumeNode(node, saneVolume);
            node.connect(_this.master);
            noiseSource.start();
            return noiseSource;
        };
        /** Play a pink noise.
         *
         * - https://noisehack.com/generate-noise-web-audio-api/
         * - http://www.musicdsp.org/files/pink.txt
         * - https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode
         */
        this.playPinkNoise = function (startFreq, endFreq, panning, volume, filterOptions) {
            var _a = _this.sanitizePlayParameters({ panning: panning, volume: volume }), sanePanning = _a.panning, saneVolume = _a.volume;
            var noiseSource = _this.context.createBufferSource();
            noiseSource.buffer = _this.pinkNoiseBuffer;
            noiseSource.loop = true;
            var node = noiseSource;
            node = _this.addPannerNode(node, sanePanning);
            node = _this.addVolumeNode(node, saneVolume);
            node = _this.addFilterNode(node, startFreq, "highpass");
            node = _this.addFilterNode(node, endFreq, "lowpass");
            // Handle filter option 'amount': controls the amount of filters to add,
            // if one filter pair is not enough.
            if (filterOptions && filterOptions.amount !== undefined) {
                for (var i = 1; i < filterOptions.amount; ++i) {
                    node = _this.addFilterNode(node, startFreq, "highpass");
                    node = _this.addFilterNode(node, endFreq, "lowpass");
                }
            }
            node.connect(_this.master);
            noiseSource.start();
            return noiseSource;
        };
        /** Shortcut to playPinkNoise (you don't want that crappy white noise, right?) */
        this.playNoise = this.playPinkNoise;
        /** Build the internal white noise buffer. */
        this.buildWhiteNoiseBuffer = function (length) {
            if (length === void 0) { length = 2; }
            var bufferSize = length * _this.context.sampleRate;
            var buffer = _this.context.createBuffer(1, bufferSize, _this.context.sampleRate);
            var bufferData = buffer.getChannelData(0);
            for (var i = 0; i < bufferSize; ++i) {
                bufferData[i] = Math.random() * 2 - 1;
            }
            return buffer;
        };
        /** Build the internal pink noise buffer. */
        this.buildPinkNoiseBuffer = function () {
            // Copy white noise buffer to pink noise buffer.
            var bufferSize = _this.whiteNoiseBuffer.length;
            var sampleRate = _this.whiteNoiseBuffer.sampleRate;
            var buffer = _this.context.createBuffer(1, bufferSize, sampleRate);
            var bufferData = buffer.getChannelData(0);
            for (var i = 0; i < bufferSize; ++i) {
                bufferData[i] = Math.random() * 2 - 1;
            }
            var b0, b1, b2, b3, b4, b5, b6;
            b0 = b1 = b2 = b3 = b4 = b5 = b6 = 0.0;
            for (var i = 0; i < bufferSize; ++i) {
                var white = bufferData[i];
                b0 = 0.99886 * b0 + white * 0.0555179;
                b1 = 0.99332 * b1 + white * 0.0750759;
                b2 = 0.96900 * b2 + white * 0.1538520;
                b3 = 0.86650 * b3 + white * 0.3104856;
                b4 = 0.55000 * b4 + white * 0.5329522;
                b5 = -0.7616 * b5 - white * 0.0168980;
                bufferData[i] = b0 + b1 + b2 + b3 + b4 + b5 + b6 + white * 0.5362;
                // bufferData[i] *= 0.11;
                b6 = white * 0.115926;
            }
            return buffer;
        };
        /** Ensure common play params have a sane value. */
        this.sanitizePlayParameters = function (params) {
            // Default values.
            var defined = {
                panning: params.panning !== undefined ? params.panning : exports.PANNING_CENTER,
                volume: params.volume !== undefined ? params.volume : exports.VOLUME_MAX
            };
            // If params are missing but an HTML input has been set, use its value.
            if (params.panning === undefined && _this.panInput !== undefined) {
                defined.panning = Number(_this.panInput.value);
            }
            if (params.volume === undefined && _this.volumeInput !== undefined) {
                defined.volume = Number(_this.volumeInput.value);
            }
            // Ensure no values are out of expected limits.
            if (defined.panning < exports.PANNING_LEFT)
                defined.panning = exports.PANNING_LEFT;
            if (defined.panning > exports.PANNING_RIGHT)
                defined.panning = exports.PANNING_RIGHT;
            if (defined.volume < exports.VOLUME_MIN)
                defined.volume = exports.VOLUME_MIN;
            if (defined.volume > exports.VOLUME_MAX)
                defined.volume = exports.VOLUME_MAX;
            return defined;
        };
        this.addPannerNode = function (input, panning) {
            // const pannerNode = this.context.createStereoPanner();
            // pannerNode.pan.value = panning;
            // input.connect(pannerNode);
            // return pannerNode;
            var pannerNode = _this.context.createPanner();
            pannerNode.setPosition(panning, 0, 0);
            input.connect(pannerNode);
            return pannerNode;
        };
        this.addVolumeNode = function (input, volume) {
            var gainNode = _this.context.createGain();
            gainNode.gain.value = volume;
            input.connect(gainNode);
            return gainNode;
        };
        this.addFilterNode = function (input, frequency, type) {
            var filterNode = _this.context.createBiquadFilter();
            filterNode.frequency.value = frequency;
            filterNode.type = type;
            input.connect(filterNode);
            return filterNode;
        };
        this.context = options.context || context_1.createContext();
        this.master = this.context.createGain();
        this.whiteNoiseBuffer = this.buildWhiteNoiseBuffer();
        this.pinkNoiseBuffer = this.buildPinkNoiseBuffer();
        this.volumeInput = options.volumeInput;
        this.panInput = options.panInput;
        this.master.connect(options.output || this.context.destination);
        this.master.gain.value = 1;
    }
    return WoPlayer;
}());
exports.WoPlayer = WoPlayer;
